<template>
    <section class="position-relative">
        <div class="row mx-0 py-3 border-bottom bg-white position-sticky">
            <div class="col my-auto pr-2">
                <el-input v-model="search" placeholder="Buscar" prefix-icon="el-icon-search" size="small" />
            </div>
            <div class="col-auto">
                <el-tooltip placement="bottom-end" effect="light" content="Nuevo chat" :visible-arrow="false" popper-class="editar-residente br-4">
                    <div class="bg-general d-middle-center rounded-circle wh-40px bs-036-29 cr-pointer" @click="$emit('new-chat')">
                        <i class="icon-message-reply-outline text-white f-25" />
                    </div>
                </el-tooltip>
            </div>
        </div>
        <div v-for="(data,key) in chatsResidentes.filter(cr => !search || String(cr.nombre).toLowerCase().includes(search.toLowerCase()))"
             :key="key" 
             :class="{'bg-activo':residenteActivo.id_user ? residenteActivo.id_user == data.id_user : ''}"
             class="row mx-0 py-3 border-bottom cr-pointer" 
             @click="getConversacion(data)"
        >
            <div class="col-9 pl-2">
                <div class="d-middle">
                    <div :class="`tipo-user wh-32px d-middle-center mr-2`">
                        <i :class="`${iconUser(data.tipo)} p-1`" />
                    </div>
                    <div class="tres-puntos">
                        <p class="f-15 f-500"> {{ data.nombre }}</p>
                        <p class="f-13 text-86 tres-puntos"> {{ data.vivienda }} </p>
                    </div>
                </div>
            </div>
            <div class="col-3 text-right">
                <p class="f-10 text-86"> {{ setFecha(data.fecha_ultimo_chat) }} </p>
                <div v-if="data.cant_no_leidos > 0" class="f-10 f-500 text-white wh-20px rounded-circle d-middle-center ml-auto mt-1" style="background: #109881;">
                    {{ data.cant_no_leidos }}
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Chats from '~/services/chats/chat-admin'
import { mapMutations, mapGetters } from 'vuex'
import moment from 'moment'
export default {
    data(){
        return {
            search: '',
        }
    },
    computed:{
        ...mapGetters({
            chatsResidentes:'chats/chatsResidentes',
            residenteActivo:'chats/residenteActivo',
            conjunto:'conjunto/conjunto'
        })
    },
    mounted(){
        this.getChatsResidentes()
        this.sockets.subscribe(`conjunto-${this.conjunto.id}-chat-general`, (data) => {
            console.log(data,'suscrito al chat general');
            this.$store.dispatch('chats/updateListado',JSON.parse(data))
        });
    },
    methods:{
        ...mapMutations({
            setChatsResidentes: 'chats/setChatsResidentes',
        }),
        async getChatsResidentes(){
            try {
                
                const {data} = await Chats.getChatsResidentes()
                this.setChatsResidentes(data.data.residentes)
               
            } catch (error){
                this.errorCatch(error)
            }
        },
        async getConversacion(payload){
            try {
                if(this.residenteActivo.id_user){
                    this.sockets.unsubscribe(`conjunto-${this.conjunto.id}-chat-residente-${this.residenteActivo.id_user}`);
                }

                this.$store.dispatch('chats/getDataResidente', payload)
                this.$store.dispatch('chats/getConversacion', payload)
                
                this.sockets.subscribe(`conjunto-${this.conjunto.id}-chat-residente-${payload.id_user}`, (data) => {
                    console.log(data,'suscrito al chat');
                    this.$store.dispatch('chats/setNewMensaje',JSON.parse(data))
                });

            } catch (error){
                this.errorCatch(error)
            }
        },
        setFecha(fecha){
            if(moment(fecha).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')){
                return moment(fecha).format('DD/MM/YYYY')
            }
            return moment(fecha).format('HH:mm a')
        }
    }

}
</script>